import React, { createContext, useState } from "react"

// Good practice to define default values for context
const defaultValues = {
  isBurgerOpen: false,
  toggleMenu: () => console.log("Menu toggled"),
  stateChangeHandler: () => console.log("Handle state"),
}

export const BurgerContext = createContext(defaultValues)

// Resources:
// https://github.com/negomi/react-burger-menu/wiki/FAQ#i-want-to-control-the-open-state-programmatically-but-i-dont-understand-how-to-use-the-isopen-prop
// https://www.npmjs.com/package/react-burger-menu

export const BurgerProvider = ({ children }) => {
  // set the initial state
  const [burgerOpenState, setBurgerOpenState] = useState(false)

  //   Set new state when menu is toggled
  const toggleMenu = () => setBurgerOpenState(!burgerOpenState)

  // Callback function to handle state change of burger menu.
  // "isOpen" property is defined in the Menu component itself, in hamburgerMenu.js
  const stateChangeHandler = newState => {
    return setBurgerOpenState(newState.isOpen)
  }

  return (
    <BurgerContext.Provider
      value={{
        ...defaultValues,
        isBurgerOpen: burgerOpenState,
        toggleMenu,
        stateChangeHandler,
      }}
    >
      {children}
    </BurgerContext.Provider>
  )
}
