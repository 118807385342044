import React from "react"
import { Link } from "gatsby"
import { MdHomeFilled, MdPhone, MdSportsHockey } from "react-icons/md"
import { FaClipboardCheck } from "react-icons/fa"

const Footer = () => {
  const pages = {
    home: ["Home", "/", <MdHomeFilled />],
    registration: ["Registration", "/registration", <FaClipboardCheck />],
    divisions: ["Divisions", "/divisions", <MdSportsHockey />],
    contact: ["Contact", "/contact", <MdPhone />],
  }

  const pageKeyArray = Object.keys(pages)

  return (
    <footer className="footer w-full">
      <div className="footer__contentContainer bg-primary text-white">
        <div className="footer__allLinks">
          {pageKeyArray.map((key, index) => (
            <Link className="footer__link" to={`${pages[key][1]}`} key={index}>
              <span className="footer__linkText">{`${pages[key][0]}`}</span>
              <span className="footer__svg" title={pages[key][0]}>
                {pages[key][2]}
              </span>
            </Link>
          ))}
        </div>
        <div className="footer__attribution">
          <div>
            Copyright © {new Date().getFullYear()} SLMHA
            <br />
            <div className="footer__borealis">
              Website by Borealis Web Development
            </div>
          </div>
        </div>
        <div className="footer__contentful">
          <a
            href="https://www.contentful.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              src="https://images.ctfassets.net/fo9twyrwpveg/7Htleo27dKYua8gio8UEUy/0797152a2d2f8e41db49ecbf1ccffdaa/PoweredByContentful_DarkBackground_MonochromeLogo.svg"
              alt="Powered by Contentful"
              className="footer__contentfulLogo"
            />
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
