/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react"

import { BurgerProvider } from "./src/context.js/BurgerContext"

// wrap root element in BurgerProvider

export const wrapRootElement = ({ element }) => (
  <BurgerProvider>{element}</BurgerProvider>
)
